import React from 'react'
import { Helmet } from 'react-helmet'

import imgFull from './firdaus-full.jpg'
import imgIntro from './firdaus-intro.jpg'

class Template extends React.Component {
  render() {
    const { children } = this.props

    return (
      <>
        <Helmet>
          <meta charset="UTF-8" />
          <title>Firdausious - Hoist the Colours</title>
          <meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no" />
          <meta name="robots" content="index, follow"/>
          <meta name="keywords" content="firdausious, firdausio.us, firdaus"/>
          <meta name="description" content="Firdausious Personal Sites"/>
          <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png"/>
          <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png"/>
          <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png"/>
          <link rel="manifest" href="/site.webmanifest"/>
          <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#2f2f2f"/>
          <meta name="apple-mobile-web-app-title" content="Firdausious"/>
          <meta name="application-name" content="Firdausious"/>
          <meta name="msapplication-TileColor" content="#2f2f2f"/>
          <meta name="theme-color" content="#2f2f2f"/>
          <link type="text/css" rel="stylesheet" href="/css/reset.css" />
          <link type="text/css" rel="stylesheet" href="/css/plugins.css" />
          <link type="text/css" rel="stylesheet" href="/css/style.css" />
          <link type="text/css" rel="stylesheet" href="/css/color.css" />
          <script type="text/javascript" src="/js/jquery.min.js"></script>
          <script type="text/javascript" src="/js/jquery.instagramFeed.min.js"></script>
          <script type="text/javascript" src="/js/plugins.js"></script>
          <script type="text/javascript" src="/js/scripts.js"></script>
        </Helmet>
          <div className="loader-wrap">
              <div className="pin"></div>
          </div>
          <div id="main">
              <header className="main-header">
                  <a className="logo-holder" href="/">
                  <img src="/images/logo.png"  alt="" />
                  </a>
                  <div className="header-social">
                      <ul >
                          <li><a href="https://www.facebook.com/firdausious" target="_blank"><i className="fab fa-facebook-f"></i></a></li>
                          <li><a href="https://www.instagram.com/firdausious" target="_blank"><i className="fab fa-instagram"></i></a></li>
                          <li><a href="https://twitter.com/firdausious" target="_blank"><i className="fab fa-twitter"></i></a></li>
                          <li><a href="https://www.linkedin.com/in/firdausious" target="_blank"><i className="fab fa-linkedin"></i></a></li>
                          <li><a href="https://github.com/firdausious" target="_blank"><i className="fab fa-github"></i></a></li>
                      </ul>
                  </div>
                  <div className="show-share showshare">
                      <i className="fal fa-retweet"></i>
                      <span>Share This</span>
                  </div>
              </header>
              <div id="wrapper">
                  <div className="scroll-nav-wrap fl-wrap">
                      <div className="scroll-down-wrap">
                          <div className="mousey">
                              <div className="scroller"></div>
                          </div>
                          <span>Firdausious</span>
                      </div>
                      <nav className="scroll-nav scroll-init">
                          <ul>
                              <li><a className="scroll-link act-link" href="#sec1">Intro</a></li>
                              <li><a className="scroll-link" href="#sec2">About</a></li>
                          </ul>
                      </nav>
                  </div>
                  <div className="hero-wrap" id="sec1" data-scrollax-parent="true">
                      <div className="hero-inner fl-wrap full-height">
                          <div className="half-hero-wrap">
                              <h1>Yo!<br />I'm  Firdaus<br />A <span> Software Craftsman </span></h1>
                              <h4>Hoist the Colours..!!</h4>
                              <div className="clearfix"></div>
                              <a href="#sec2" className="custom-scroll-link btn float-btn flat-btn color-btn mar-top">More about me</a> 
                          </div>
                          <div className="half-bg-dec" data-ran="12"></div>
                          <div className="half-bg">
                              <div className="bg"  data-bg={imgIntro} data-scrollax="properties: { translateY: '250px' }" ></div>
                              <div className="overlay"></div>
                          </div>
                          <div className="hero-decor-let">
                              <div>Web / Mobile Apps</div>
                              <div><span>Eater Master</span></div>
                              <div>Engineering</div>
                              <div><span>Sleeping Badass</span></div>
                              <div>Design System</div>
                          </div>
                          <div className="hero-decor-numb"><span>-7.2755979  </span><span>112.572597 </span> <a href="https://previews.dropbox.com/p/thumb/AA0mD06ZFVXzfrOE3B_k4Bq8wefWRAkGRCcfdYidD-xjM8Qb5GQug-l_je5kUGZlo3J1sCHF0H8h_vV3WK6sy05dDagQFyFthE_ItQkM1wmAn0s6Med-LZQMww-fPrzmhwZGBsx0IAY_fbFiCkqmP2fVjMbFLkPWW5ThTuNopI--7e-1UKt4dPdNTLEzoP4i578gYSpYj1EUFtGRr0t1ePgEMY-7VzLFOsCV1GpVwmKguyIZOF2TB3TdX-uCo8i24_P-WLq0dK2m30uweJhF43Zll1Q1X6i-dk_r2UV0ZaaehWJRF8hq41sanUZ5w287n3pEtmCvj1EJyKBIk8OI15tFcsxy8bGrqIfR8scm0v3SYw/p.jpeg" target="_blank" className="hero-decor-numb-tooltip">Based In Surabaya</a></div>
                          <div className="pattern-bg"></div>
                          <div className="hero-line-dec"></div>
                      </div>
                  </div>
                  <div className="content">
                      <section data-scrollax-parent="true" id="sec2">
                          <div className="section-subtitle"  data-scrollax="properties: { translateY: '-250px' }" > <span>//</span>Words About  </div>
                          <div className="container">
                              <div className="row">
                                  <div className="col-md-4">
                                      <div className="collage-image fl-wrap">
                                          <div className="collage-image-title">It's Me..</div>
                                          <img src={imgFull} className="respimg" alt="" />
                                      </div>
                                  </div>
                                  <div className="col-md-7">
                                      <div className="main-about fl-wrap">
                                          <h5>About Me</h5>
                                          <h2>Hi there,<br />I'm <span> Mochammad Firdaus </span></h2>
                                          <br />
                                          <p>My name is <i>Mochammad Firdaus Fatchur Rozi</i>, but people usually call me <strong>Firdaus</strong> or <strong>Roz</strong>.<br/> A father and husband who loves STEM, foods, and musics.</p>
                                          <p>ENFJ, male, 28-years old, living in Surabaya, Indonesia, who now focuses on ReactJS and React Native. Big fans of Universal and Cross Platform Apps, Architectures, Design System, DevOps, and Software Craftsmanship.</p>
                                          <p>Currently working at <a href="" target="_blank">Warung Pintar</a> as Software Engineer. Building frontend stuff on web and mobile apps for micro retail technology with a mission of transforming micro business for Indonesia’s future economy.</p>
                                          <br />
                                          <a href="#" className="btn float-btn flat-btn color-btn">Save my contact</a>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div className="bg-parallax-module" data-position-top="90"  data-position-left="25" data-scrollax="properties: { translateY: '-250px' }"></div>
                          <div className="bg-parallax-module" data-position-top="70"  data-position-left="70" data-scrollax="properties: { translateY: '150px' }"></div>
                          <div className="sec-lines"></div>
                      </section>
                      <section className="dark-bg2 small-padding order-wrap">
                          <div className="container">
                              <div className="row">
                                  <div className="col-md-8">
                                      <h3>"Don’t worry if it doesn’t work right. If everything did, you’d be out of a job."<br/>Mosher’s Law of Software Engineering</h3>
                                  </div>
                              </div>
                          </div>
                      </section>
                  </div>
                  <div className="height-emulator fl-wrap"></div>
                  <div className="height-emulator fl-wrap"></div>
                  <footer className="main-footer fixed-footer">
                      <div className="footer-inner fl-wrap">
                          <div className="container">
                              <div className="partcile-dec" data-parcount="90"></div>
                              <div className="row">
                                  <div className="col-md-2">
                                      <div className="footer-title fl-wrap">
                                          <span>Firdausious</span>
                                      </div>
                                  </div>
                                  <div className="col-md-5">
                                      <div className="footer-header fl-wrap"><span>01.</span>Last Twitts</div>
                                      <div className="footer-box fl-wrap">
                                          <div className="twitter-swiper-container fl-wrap" id="twitts-container"></div>
                                          <a href="#" className="btn float-btn trsp-btn">Follow</a>
                                      </div>
                                  </div>
                                  <div className="col-md-5">
                                      <div className="footer-header fl-wrap"><span>02.</span> Last Updates</div>
                                      <div className="footer-box fl-wrap">
                                          <div id="instagram-container" style={{ height: '150px', marginBottom: '8px'}}></div>
                                          <div className="footer-contacts fl-wrap">
                                              <ul>
                                                  <li><i className="fal fa-phone"></i><span>Phone :</span><a href="#">+62 822 450307 97</a></li>
                                                  <li><i className="fal fa-envelope"></i><span>Email :</span><a href="#">firdausious@gmail.com</a></li>
                                                  <li><i className="fal fa-map-marker"></i><span>Address :</span><a href="#">Surabaya, Indonesia</a></li>
                                              </ul>
                                          </div>
                                          <div className="footer-socilal fl-wrap">
                                              <ul >
                                                  <li><a href="https://www.facebook.com/firdausious" target="_blank"><i className="fab fa-facebook-f"></i></a></li>
                                                  <li><a href="https://www.instagram.com/firdausious" target="_blank"><i className="fab fa-instagram"></i></a></li>
                                                  <li><a href="https://twitter.com/firdausious" target="_blank"><i className="fab fa-twitter"></i></a></li>
                                                  <li><a href="https://www.linkedin.com/in/firdausious" target="_blank"><i className="fab fa-linkedin"></i></a></li>
                                                  <li><a href="https://github.com/firdausious" target="_blank"><i className="fab fa-github"></i></a></li>
                                              </ul>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div className="subfooter fl-wrap">
                          <div className="container">
                              <div className="policy-box">
                                  <span>&#169; Firdausious 2020 </span>
                              </div>
                              <a href="#" className="to-top color-bg"><i className="fal fa-angle-up"></i><span></span></a>
                          </div>
                      </div>
                  </footer>
              </div>
              <div className="share-wrapper isShare">
                  <div className="share-title"><span>Share</span></div>
                  <div className="close-share soa"><span>Close</span><i className="fal fa-times"></i></div>
                  <div className="share-inner soa">
                      <div className="share-container"></div>
                  </div>
              </div>
          </div>
      </>
    )
  }
}

export default Template
